/* eslint-disable */
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  GitlabGroupPicker,
  GitlabGroupPickerValidation,
} from './GitlabGroupPickerExtension';

export const ValidateGitlabGroupFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GitlabGroupPicker',
    component: GitlabGroupPicker,
    validation: GitlabGroupPickerValidation
  }),
);