import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { apiAwanaJira, apiIntellicence, apiIntelligence, apiJira } from './api';

export const awanaToolsPlugin = createPlugin({
  id: 'awana-tools',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: apiIntellicence,
      deps: { discoveryApi: configApiRef },
      factory: ({ discoveryApi }) => {
        const backendUrl = discoveryApi.get("awanaIA.baseUrl")?.toLocaleString();
        return new apiIntelligence(backendUrl?backendUrl:"");
      },
    }),
    createApiFactory({
      api: apiAwanaJira,
      deps: { discoveryApi: configApiRef },
      factory: ({ discoveryApi }) => {
        const backendUrl = discoveryApi.getOptionalString("backend.baseUrl")
        if (backendUrl === undefined) {
          throw new Error("Backend URL not found");
        }
        return new apiJira(backendUrl);
      },
    })
  ]
});

export const AwanaToolsPage = awanaToolsPlugin.provide(
  createRoutableExtension({
    name: 'AwanaToolsPage',
    component: () =>
      import('./components/AwanaTools').then(m => m.AwanaTools),
    mountPoint: rootRouteRef,
  }),
);
