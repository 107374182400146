import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const awanaTestgeniusPlugin = createPlugin({
  id: 'awana-testgenius',
  routes: {
    root: rootRouteRef,
  },
});

export const AwanaTestgeniusPage = awanaTestgeniusPlugin.provide(
  createRoutableExtension({
    name: 'AwanaTestgeniusPage',
    component: () =>
      import('./components/TestGenius').then(m => m.TestGenius),
    mountPoint: rootRouteRef,
  }),
);
