import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { EntitySwitch } from '@backstage/plugin-catalog';
import { EmptyState } from '@backstage/core-components';
import { isFlushcacheAvailable, FlushcacheEntityCard } from '@internal/backstage-plugin-flushcache';

const FlushCacheMissingAnnotation = (
  <EmptyState
    title="Flush Cache annotation missing"
    missing="info"
    description="You need to add an annotation to your component if you want to enable Flush Cache for it. You can read more about annotations in Khatu-X by clicking the button below."
    action={
      <Button
        variant="contained"
        color="primary"
        href="https://naranjafintech.atlassian.net/l/cp/UMJs9si6"
        target="_blank"
      >
        Read more
      </Button>
    }
  />
);

/**
 * # My AWS Lambda Tab content
 * @returns JSX.Element
 */
export const FlushCacheTab = (
  <Grid container spacing={3}>
    <EntitySwitch>
      <EntitySwitch.Case if={e => Boolean(isFlushcacheAvailable(e))}>
        <Grid item xs={12}>
          <FlushcacheEntityCard />
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case>{FlushCacheMissingAnnotation}</EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
