
export function generateBodyStoryTeller(titulo:string, rol:string, funcionalidad:string, beneficio:string) {
    return {
        input: {
            titulo: titulo,
            rol: rol,
            funcionalidad: funcionalidad,
            beneficio: beneficio,
            necesidad:
                'N/A',
            regla_negocio: 'N/A',
            alcance: 'N/A',
            fuera_alcance: 'N/A',
            recursos: 'N/A',
            criterio_aceptacion: 'N/A',
            criterio_exito: 'N/A',
        },
    };
}

export function generateBodyJiraAddIssue(title:string, description:string, project:string, epic:string) {
    const bodyFull = {
        "fields": {
            "project": {
                "key": project
            },
            "summary": title,
            "description": description,
            "issuetype": {
                "name": "Story"
            },
            "parent": {
                "key": epic
            }
        }
    }

    const bodySinParent = {
        "fields": {
            "project": {
                "key": project
            },
            "summary": title,
            "description": description,
            "issuetype": {
                "name": "Story"
            }
        }
    }

    return epic ? bodyFull : bodySinParent;
}

export function formatErrorMessages(jsonData:any):string {
    let formattedErrors = "";
  
    if (jsonData.errorMessages && jsonData.errorMessages.length > 0) {
      formattedErrors += jsonData.errorMessages.join('\n');
    }
  
    if (jsonData.errors) {
      for (const key in jsonData.errors) {
        if (jsonData.errors.hasOwnProperty(key)) {
          formattedErrors += `\n${key}: ${jsonData.errors[key]}`;
        }
      }
    }
  
    return formattedErrors.trim(); 
  }

export function agregarPaneles(texto1:string) {
    const filas = texto1.split('\n');

    filas.splice(0, 4);

    const texto = filas.join('\n');

    const patronInicioParrafo = /\n +/g;
  
    const patronFinParrafo = /\n(?={panel:bgColor=#deebff})+/g;
  
    let nuevoTexto = texto.replace(patronInicioParrafo, "\n{panel:bgColor=#deebff}");
    
    nuevoTexto = nuevoTexto.replace(patronFinParrafo,"{panel}\n\n");

    const filas1 = nuevoTexto.split('\n');

    filas1.splice(0,2);

    nuevoTexto = filas1.concat("{panel}").join('\n')

    return nuevoTexto
}