import { AwsLambdaApi } from './AWSLambdaApi';
import { LambdaData } from '../types';
import { LambdaClient, GetFunctionCommand } from "@aws-sdk/client-lambda";

async function generateCredentials(
  backendUrl: string,
  options: {
    token: string | undefined;
  },
  roleArn: string | undefined,
) {
  const respData = await fetch(`${backendUrl}/api/aws-auth/credentials`, {
    method: 'POST',
    headers: {
      // Disable eqeqeq rule for next line to allow it to pick up both undefind and null
      // eslint-disable-next-line eqeqeq
      ...((options == null ? void 0 : options.token) && {
        Authorization: `Bearer ${options === null ? void 0 : options.token}`,
      }),
      'Content-Type': 'application/json',
    },
    body: roleArn === null ? void 0 : JSON.stringify({ RoleArn: roleArn }),
  });
  try {
    const resp = await respData.json();
    return resp;
  } catch (e: any) {
    throw new Error('MissingBackendAwsAuthException');
  }
}
export class AwsLambdaClient implements AwsLambdaApi {

  async getFunctionByNames({
    awsRegion,
    backendUrl,
    functionNames,
    token,
    roleArn,
  }: {
    awsRegion: string;
    backendUrl: string;
    functionNames: string[];
    token?: string;
    roleArn?: string | undefined;
  }): Promise<LambdaData[]> {
    const credentials = await generateCredentials(
      backendUrl,
      { token },
      roleArn,
    );
    const client = new LambdaClient({
      region: awsRegion,
      credentials,
    });
    const resps = Promise.all(
      functionNames.map(async funName => {
        const input = { // GetFunctionRequest
          FunctionName: funName,
        };
        const command = new GetFunctionCommand(input);
        const resp = await client.send(command);
        
        const v = resp.Configuration!;
        return {
          codeSize: v.CodeSize!,
          description: v.Description!,
          functionName: v.FunctionName!,
          lastModifiedDate: v.LastModified!,
          runtime: v.Runtime!,
          memory: v.MemorySize!,
          region: awsRegion,
          state: v.State!,
          lastUpdateStatus: v.LastUpdateStatus!,
          lastUpdateStatusReason: v.LastUpdateStatusReason!,
        };
      }),
    );
    return resps;
  }
}
