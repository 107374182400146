import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { EmptyState } from '@backstage/core-components';
import { EntitySwitch } from '@backstage/plugin-catalog';
import { EntityJiraDashboardContent, isJiraDashboardAvailable } from '@internal/backstage-plugin-jira-dashboard';

const JiraDashboardAnnotationsMissing = (
  <EmptyState
    title="Jira Dashboard annotations missing"
    missing="info"
    description="You need to add an annotation to your component if you want to enable JIRA for it. You can read more about annotations in Khatu-X by clicking the button below."
    action={
      <Button
        variant="contained"
        color="primary"
        href="https://naranjafintech.atlassian.net/wiki/spaces/KHAT/pages/527590719664/Jira+Backstage+Plugin"
        target='_blank'
      >
        Read more
      </Button>
    }
  />
);

export const JiraDashboardTab = (
  <Grid container spacing={3}>
    <EntitySwitch>
      <EntitySwitch.Case if={entity => Boolean(isJiraDashboardAvailable(entity, 'jira'))}>
        <Grid item xs={12}>
          <EntityJiraDashboardContent />
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case>{JiraDashboardAnnotationsMissing}</EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
