import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import {
  genPageTheme,
  shapes,
  createUnifiedTheme,
  createBaseThemeOptions,
  palettes,
  UnifiedThemeProvider,
} from '@backstage/theme';

const khatuLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#50007F',
      },
      secondary: {
        main: '#565a6e',
      },
      error: {
        main: '#8c4351',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      navigation: {
        background: '#fff',
        indicator: '#fff',
        color: '#333b',
        selectedColor: '#50007F',
        navItem: {
          hoverBackground: '#fff',
        },
      },
      background: {
        default: '#F5F5F4',
        paper: '#fff',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#565a6e',
      },
    },
  }),
  fontFamily: 'Gibson',
  pageTheme: {
    home: genPageTheme({
      colors: ['#fff', '#fff'],
      shape: shapes.wave,
      options: {
        fontColor: '#FE5000',
      },
    }),
  },
});

const khatuDarkTheme = createUnifiedTheme({
  palette: {
    ...palettes.dark,
    primary: {
      main: '#f5f5f5',
    },
    secondary: {
      main: '#d45500',
    },
    error: {
      main: '#8c4351',
    },
    warning: {
      main: '#8f5e15',
    },
    info: {
      main: '#34548a',
    },
    success: {
      main: '#485e30',
    },
    navigation: {
      background: '#333333',
      indicator: '#fff',
      color: '#f5f5f5',
      selectedColor: '#f5f5f5',
      navItem: {
        hoverBackground: '#1a1a1a',
      },
    },
    background: {
      default: '#1a1a1a',
      paper: '#333333',
    },
    banner: {
      info: '#34548a',
      error: '#8c4351',
      text: '#343b58',
      link: '#565a6e',
    },
  },
  fontFamily: 'Gibson',
  pageTheme: {
    home: genPageTheme({
      colors: ['#50007F', '#50007F'],
      shape: shapes.wave,
      options: {
        fontColor: '#F5F5F4',
      },
    }),
  },
});

export const CustomLightThemeProvider = ({
  children,
}: { children: JSX.Element }) => {
  return (
    <UnifiedThemeProvider theme={khatuLightTheme}>
      <CssBaseline>{children}</CssBaseline>
    </UnifiedThemeProvider>
  );
};

export const CustomDarkThemeProvider = ({
  children,
}: { children: JSX.Element }) => {
  return (
    <UnifiedThemeProvider theme={khatuDarkTheme}>
      <CssBaseline>{children}</CssBaseline>
    </UnifiedThemeProvider>
  );
};
