import { Button, Grid } from '@material-ui/core';
import { EntitySwitch } from '@backstage/plugin-catalog';
import { EmptyState } from '@backstage/core-components';
import { EntityMyAWSLambdaOverviewCard, isAWSLambdaAvailable } from '@internal/backstage-plugin-my-aws';
import React from 'react';

const AwsMissingAnnotation = (
  <EmptyState
    title="AWS annotation missing"
    missing="info"
    description="You need to add an annotation to your component if you want to enable AWS for it. You can read more about annotations in Khatu-X by clicking the button below."
    action={
      <Button
        variant="contained"
        color="primary"
        href="https://naranjafintech.atlassian.net/wiki/spaces/KHAT/pages/527618408554/AWS+Lambdas"
        target="_blank"
      >
        Read more
      </Button>
    }
  />
);

/**
 * # My AWS Lambda Tab content
 * @returns JSX.Element
 */
export const AwsTab = (
  <Grid container spacing={3}>
    <EntitySwitch>
      <EntitySwitch.Case if={e => Boolean(isAWSLambdaAvailable(e))}>
        <Grid item xs={12}>
          <EntityMyAWSLambdaOverviewCard />
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case>{AwsMissingAnnotation}</EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
