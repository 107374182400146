import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import logov2 from './logo-v2.png';
import logov3 from './logo-v3.png';

const LogoFull = () => {
  const theme: any = useTheme();
  const mode: 'dark' | 'light' = theme.palette.mode;

  return (
    (mode === 'dark') 
    ?
    <img width={160} height={150} src={logov2} alt="" />
    :
    <img width={160} height={150} src={logov3} alt="" />
  );
};

export default LogoFull;
