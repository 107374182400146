import { createApiRef } from '@backstage/core-plugin-api';
import { DomainDto, DryRunDto, GroupDto, SystemDto } from '../interfaces/dto';
import { ApiError } from './Error';
import { CommitResponse, IAction, ICommitPayload } from '../interfaces/gitlab';

export class SoftwareCatalogApi {
  private readonly token!: string | null;
  private readonly project_id!: number;
  private readonly project_branch!: string;
  public readonly baseProjectUrl =
    'https://gitlab.com/Naranja-Digital/golden-groups/shared-entities';

  constructor(public url: string, token?: string) {
    this.token = token ?? window.localStorage.getItem('KhatuToken');
    this.project_id = 60851828;
    this.project_branch = 'integration';
  }

  /**
   * Fetches a list of groups from the catalog.
   *
   * @param type if set, will filter the result by the given type
   */
  public async findGroups(type?: string): Promise<GroupDto[] | never> {
    const url = type
      ? `${this.url}/api/catalog/entities?filter=kind%3Dgroup%2Cspec.type%3D${type}`
      : `${this.url}/api/catalog/entities?filter=kind%3Dgroup`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.token ? `Bearer ${this.token}` : '',
      },
    });

    if (!response.ok) {
      throw new ApiError(response.statusText, response.status);
    }

    const json = await response.json();
    return json as GroupDto[];
  }

  /**
   * Fetches a list of domains from the catalog.
   */
  public async findDomains(): Promise<DomainDto[] | never> {
    const url = `${this.url}/api/catalog/entities?filter=kind%3Ddomain`;
    const response = await fetch(url, {
      // Use GET method
      method: 'GET',
      headers: {
        // Set the content-type to application/json
        'Content-Type': 'application/json',
        // Set the authorization header with the token
        authorization: this.token ? `Bearer ${this.token}` : '',
      },
    });

    if (!response.ok) {
      // Throw an error if the response is not ok
      throw new ApiError(response.statusText, response.status);
    }

    const json = await response.json();
    // Return the list of domains
    return json as DomainDto[];
  }

  /**
   * Fetches a list of systems from the catalog.
   */
  public async findSystems(): Promise<SystemDto[] | never> {
    const url = `${this.url}/api/catalog/entities?filter=kind%3Dsystem`;
    const response = await fetch(url, {
      // Use GET method
      method: 'GET',
      headers: {
        // Set the content-type to application/json
        'Content-Type': 'application/json',
        // Set the authorization header with the token
        authorization: this.token ? `Bearer ${this.token}` : '',
      },
    });

    if (!response.ok) {
      // Throw an error if the response is not ok
      throw new ApiError(response.statusText, response.status);
    }

    const json = await response.json();
    // Return the list of systems
    console.log(json);
    return json as SystemDto[];
  }

  public async sendToGitlab(
    actions: IAction[],
  ): Promise<CommitResponse | never> {
    const url = `${this.url}/api/proxy/gitlabci/projects/${this.project_id}/repository/commits`;
    const payload: ICommitPayload = {
      actions,
      branch: this.project_branch,
      commit_message: 'software catalog plugin integration',
    };
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        authorization: this.token ? `Bearer ${this.token}` : '',
      },
    });
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status);
    }
    const json = await response.json();
    return json as CommitResponse;
  }

  public async dryRun(
    basePath: string,
    entityName: string,
  ): Promise<DryRunDto | never> {
    const urlWithDryRun = `${this.url}/api/catalog/locations?dryRun=true`;
    const urlWithoutDryRun = `${this.url}/api/catalog/locations`;

    const payload = {
      target: `${this.baseProjectUrl}/blob/integration/${basePath}/${entityName}.yaml`,
      type: 'url',
    };
    const dryRunResponse = await fetch(urlWithDryRun, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        authorization: this.token ? `Bearer ${this.token}` : '',
      },
    });

    if (!dryRunResponse.ok) {
      throw new ApiError(dryRunResponse.statusText, dryRunResponse.status);
    }

    const dryRunJson: DryRunDto = await dryRunResponse.json();

    if (dryRunJson.exists) {
      return dryRunJson;
    }

    const noDryRunResponse = await fetch(urlWithoutDryRun, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        authorization: this.token ? `Bearer ${this.token}` : '',
      },
    });

    if (!noDryRunResponse.ok) {
      throw new ApiError(noDryRunResponse.statusText, noDryRunResponse.status);
    }

    const json = await dryRunResponse.json();
    return json as DryRunDto;
  }

  public query(body: Record<string, any>) {
    const item = window.localStorage.getItem('KhatuToken');
    return fetch(this.url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        authorization: item ? `Bearer ${item}` : '',
      },
    });
  }
}

export const softwareCatalogApiRef = createApiRef<SoftwareCatalogApi>({
  id: 'plugin.softwarecatalog.service',
});
