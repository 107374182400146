import { createApiRef } from '@backstage/core-plugin-api';
import { generateBodyJiraAddIssue, generateBodyStoryTeller } from './utils';

export class apiIntelligence {
  constructor(public baseUrl: String) {}

  public async postStoryTeller(
    titulo: string,
    rol: string,
    funcionalidad: string,
    beneficio: string,
  ) {
    const body = generateBodyStoryTeller(titulo, rol, funcionalidad, beneficio);
    const response = await fetch(`${this.baseUrl}/storyteller/generate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
    return response.json();
  }

  public async postCraftest(body: any) {
    const response = await fetch(`${this.baseUrl}/craftest/generate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
    return response.json();
  }

  public async postCraftestAgent(body: any) {
    const response = await fetch(`${this.baseUrl}/agent/craftest`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
    return response.json();
  }

  public async postTestGenius(body: any) {
    const response = await fetch(`${this.baseUrl}/testgenius/test`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
    return response.json();
  }
}

export class apiJira {
  private readonly _backsdtageHeader: Record<string, any> = {};

  constructor(public baseUrl: string) {
    this._backsdtageHeader = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('KhatuToken')}`,
    };
  }

  public async postIsue(
    title: string,
    description: string,
    project: string,
    epic: string,
  ) {
    const body = generateBodyJiraAddIssue(title, description, project, epic);
    const response = await fetch(`${this.baseUrl}/api/proxy/awana-jira`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: this._backsdtageHeader,
    });
    return response.json();
  }
}

export const apiIntellicence = createApiRef<apiIntelligence>({
  id: 'awanaia.intellicenceapi.service',
});

export const apiAwanaJira = createApiRef<apiJira>({
  id: 'awana.jira.service',
});
