import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRouteRef,
} from '@backstage/core-plugin-api';
import { awsLambdaApiRef, AwsLambdaClient } from './api';

export const entityContentRouteRef = createRouteRef({
  id: 'AWS Lambda Entity Content',
});


export const myAwsPlugin = createPlugin({
  id: 'my-aws',
  apis: [createApiFactory(awsLambdaApiRef, new AwsLambdaClient())],
  routes: {
    entityContent: entityContentRouteRef,
  },
});

/**
 * Props of EntityAWSLambdaOverviewCard
 *
 * @public
 */
export type EntityAWSLambdaOverviewCardProps = {
  /**
   * Sets the accountId for where it should find the lambdas
   */
  roleArn?: string;
};

export const EntityMyAWSLambdaOverviewCard: (
  props: EntityAWSLambdaOverviewCardProps,
) => JSX.Element = myAwsPlugin.provide(
  createComponentExtension({
    name: 'EntityAWSLambdaOverviewCard',
    component: {
      lazy: () =>
        import('./components/AWSLambdaOverview/AWSLambdaOverview').then(
          m => m.AWSLambdaOverviewWidget,
        ),
    },
  }),
);

