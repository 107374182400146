/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FieldProps, FieldValidation } from '@rjsf/utils';
import { Autocomplete, Alert } from '@material-ui/lab';
import { Container, Grid, TextField, Typography } from '@material-ui/core';

import { useCoreQuery } from '../../hooks/useCoreQuery';

const SEARCH_DESENCADENANTS_GROUPS = `#graphql
query SEARCH_DESENCADENANTS_GROUPS($search: String!){
    searchDescendantsGroups(search:$search) {
        description
        full_path
        id
        name
        projects_count
    }
}
`;

interface DescendantsGroupsResponse {
  data: null | { searchDescendantsGroups: DescendantGroup[] };
  errors?: any;
}
interface DescendantGroup {
  id: number;
  full_path: string;
  name: string;
  projects_count: number;
  description?: string;
}

export const GitlabGroupPicker = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldProps<string>) => {
  const [gitlabGroupPicker, setGitlabGroupPicker] = useState(
    String(
      String(formData ?? '')
        .replace('gitlab.com?owner=', '')
        .split('&')[0] ?? '',
    ).replaceAll('%2F', '/'),
  );
  const [projectName, setProjectName] = useState(String(formData ?? "").replace("repo=", "").split('&')[1] ?? "");

  const [descendantsGroups, setDescendantsGroups] = useState<string[]>([]);
  const { runQuery } = useCoreQuery('NON_PROD');

  useEffect(() => {
    if (gitlabGroupPicker.trim().length > 0 && projectName.trim().length > 0) {
      const owner = gitlabGroupPicker.split('/').join('%2F');
      const repo = projectName.toLowerCase().split(' ').join('-');
      const repo_url = `gitlab.com?owner=${owner}&repo=${repo}`;
      onChange(repo_url);
    }
  }, [projectName, gitlabGroupPicker]);

  useEffect(() => {
    const el = document.querySelector('#root_name');
    if (el !== null) {
      setProjectName(el.getAttribute('value') ?? '');
    }
  }, []);

  const onAutocompleteChange = (_: any, onChangeValue: string | null) => {
    if (onChangeValue !== null) {
      const item = descendantsGroups.find(el => el === onChangeValue);
      setGitlabGroupPicker(String(item ?? ''));
    } else {
      setGitlabGroupPicker('');
    }
  };

  const onAutoCompleteTextFieldChange = async (search: string) => {
    if (search.trim().length > 1) {
      const response = await runQuery<DescendantsGroupsResponse>({
        query: SEARCH_DESENCADENANTS_GROUPS,
        variables: { search },
      });
      const paths = response?.data?.searchDescendantsGroups ?? [];
      setDescendantsGroups(paths.map(p => p.full_path));
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        direction="column"
        spacing={3}
        style={{ marginBottom: 3 }}
      >
        <Grid item xs>
          <Typography variant="body1">
            Find the gitlab group where you want to save this Software
            Component.
          </Typography>
        </Grid>
        <Grid item xs>
          <Autocomplete
            options={descendantsGroups}
            fullWidth
            value={gitlabGroupPicker}
            getOptionLabel={v => v}
            getOptionSelected={opt => opt === gitlabGroupPicker}
            onChange={onAutocompleteChange}
            renderInput={props => (
              <TextField
                {...props}
                required={required}
                error={Number(rawErrors?.length ?? 0) > 0 && !formData}
                onChange={e => onAutoCompleteTextFieldChange(e.target.value)}
                variant="outlined"
                label="Find Group by Name"
                placeholder="write a Group Name"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <Alert severity="warning">
            <Typography variant="h6">Keep this in mind:</Typography>
            <Typography variant="body1">
              The group finder browse within all <em>gitlab.com/naranja-digital</em>. You'll
              can save your Software Component in any group, <u>even in groups
              where YOU AREN'T MEMBER</u>
            </Typography>
          </Alert>
        </Grid>
        <Grid item xs>
          <Typography variant="body1">
            Write a name for you new Repository
          </Typography>
        </Grid>
        <Grid item xs>
          <TextField
            variant="outlined"
            placeholder="my-new-repository-name"
            fullWidth
            required={required}
            value={projectName}
            label="Repository Name"
            onChange={e => setProjectName(e.target.value)}
            error={Number(rawErrors?.length ?? 0) > 0 && !formData}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export const GitlabGroupPickerValidation = (
  value: string,
  validation: FieldValidation,
) => {
  if (value.trim().length === 0) {
    validation.addError(
      `Group path is required. Like "naranja-digital/some-found-group-name"`,
    );
  }
};
