import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const awanaCraftestPlugin = createPlugin({
  id: 'awana-craftest',
  routes: {
    root: rootRouteRef,
  },
});

export const AwanaCraftestPage = awanaCraftestPlugin.provide(
  createRoutableExtension({
    name: 'AwanaCraftestPage',
    component: () =>
      import('./components/Craftest').then(m => m.Craftest),
    mountPoint: rootRouteRef,
  }),
);
