import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { EntitySwitch } from '@backstage/plugin-catalog';
import { EmptyState } from '@backstage/core-components';
import { NthunderComponent, isNthunderAvailable } from '@internal/backstage-plugin-nthunder';

const NthunderMissingAnnotation = (
  <EmptyState
    title="Nthunder annotation missing"
    missing="info"
    description="You need to add an annotation to your component if you want to enable Vulnerabilities for it. You can read more about annotations in Khatu-X by clicking the button below."
    action={
      <Button
        variant="contained"
        color="primary"
        href="https://naranjafintech.atlassian.net/l/cp/Lj8nj6f6"
        target="_blank"
      >
        Read more
      </Button>
    }
  />
);

/**
 * # My Nthunder Tab content
 * @returns JSX.Element
 */
export const NthunderTab = (
  <Grid container spacing={3}>
    <EntitySwitch>
      <EntitySwitch.Case if={e => Boolean(isNthunderAvailable(e))}>
        <Grid item xs={12}>
          <NthunderComponent />
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case>{NthunderMissingAnnotation}</EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
