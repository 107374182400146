import { createApiRef } from '@backstage/core-plugin-api';
import { LambdaData } from '../types';

export const awsLambdaApiRef = createApiRef<AwsLambdaApi>({
  id: 'plugin.awslambda.service',
});

export type AwsLambdaApi = {
  getFunctionByNames: ({
    awsRegion,
    backendUrl,
    functionNames,
    token,
    roleArn,
  }: {
    awsRegion: string;
    backendUrl: string;
    functionNames: string[];
    token?: string;
    roleArn?: string;
  }) => Promise<LambdaData[]>;
};
